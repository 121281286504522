import { Component, Inject, OnInit, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface PreviewDocumentDialogInput {
  idUrl: string | null;
  base64Image: { mediaType: string; image: string } | null;
}

@Component({
  selector: "app-view-gov-id",
  templateUrl: "./preview-document-dialog.component.html",
  styleUrls: ["./preview-document-dialog.component.scss"],
})
export class PreviewDocumentDialogComponent implements OnInit {
  dialogRef: MatDialogRef<PreviewDocumentDialogComponent> = inject(
    MatDialogRef<PreviewDocumentDialogComponent>
  );
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PreviewDocumentDialogInput
  ) {}
  imgUrl: string | null = null;
  mediaType: string | null = null;
  async ngOnInit() {
    if (this.data.base64Image) {
      const blob = await (
        await fetch(
          `data:${this.data.base64Image.mediaType};base64,${this.data.base64Image.image}`
        )
      ).blob();
      this.mediaType = this.data.base64Image.mediaType;
      this.imgUrl = URL.createObjectURL(blob);
      window.open(this.imgUrl, "_blank");
    } else if (this.data.idUrl) {
      window.open(this.data.idUrl, "_blank");
    }
    this.close();
  }
  close() {
    this.dialogRef.close();
  }
}
