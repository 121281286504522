export type GovernmentIdType =
  | "Drivers License"
  | "US Passport"
  | "Foreign Passport"
  | "State/Local/Tribe issued ID"
  | "Tribal ID"
  | "Other";

export type ReportType =
  | "Handoff"
  | "Exemption Certificate"
  | "BOIR - Initial"
  | "BOIR - Update"
  | "BOIR - Correction";

export type CoverLetterType = "Handoff" | "SelfBill" | "Direct";
