import { IAppEnv } from "src/app/core/interfaces";

export const dontImportMe: IAppEnv = {
  firebase: {
    apiKey: "AIzaSyCEl4juwmyjMTdUy67TgTjW3w7Xc7guXlM",
    authDomain: "compliance---staging.firebaseapp.com",
    projectId: "compliance---staging",
    storageBucket: "compliance---staging.appspot.com",
    messagingSenderId: "369447267673",
    appId: "1:369447267673:web:b3edcf6a3187ec35d47048",
    measurementId: "G-TGKSD2NCJX",
  },
  stripeKey:
    "pk_test_51OAMSvErs4o4r2jgrZ8UhEiQvbKLSSAz7FjQz0TlwchanpVxEgOrj8W8OVNuMdbLvn6dQRyeppXkpWn4hpLZWjtS00PaVDnGfd",
  envType: "staging",
};
