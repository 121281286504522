import { Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BoirStatusResponse } from "src/app/core/services/boir-filings.service";

@Component({
  selector: "app-status-dialog",
  templateUrl: "./status-dialog.component.html",
  styleUrls: ["./status-dialog.component.scss"],
})
export class StatusDialogComponent {
  public dialogRef: MatDialogRef<StatusDialogComponent> = inject(
    MatDialogRef<StatusDialogComponent>
  );
  public data: { status: BoirStatusResponse } = inject(MAT_DIALOG_DATA);
}
