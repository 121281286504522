import {
  ICoverLetterData,
  ICustomCoverLetterContent,
} from "src/app/core/interfaces";

export const coverLetterDefaults: ICustomCoverLetterContent = {
  Handoff: [
    {
      attributes: {
        color: "#1b6f91",
        bold: true,
      },
      insert: "{Date}",
    },
    {
      insert:
        "\n\nGreetings,\n\n{Pro Org Name} has completed {Company}'s BOI Report using Secure Compliance. Now it's your turn! ",
    },
    {
      attributes: {
        background: "var(--mdc-elevated-card-container-color)",
        color: "var(--mat-sidenav-content-text-color)",
      },
      insert: "Here's your next steps for Company's BOI compliance:",
    },
    {
      insert: "\n\nReview your Report",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert: "Sign and Authorize this Filing",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Watch your Email for FinCEN’s Acceptance or Rejection of your Report",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Report any Changes of Information in your SecureFILE Dashboard and Submit",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "\nFrom here, you will be managing {Company}'s compliance. This means that when certain information changes about your company or your beneficial owners, you will need to file an updated report with FinCEN ",
    },
    {
      attributes: {
        bold: true,
      },
      insert: "within 30 days",
    },
    {
      insert:
        " of when the change occurred. Here's the most common triggers that result in needing to file an updated report:\nChange in the residential address of a beneficial owner",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Death of a beneficial owner – an updated report will need to be filed that excludes this late owner's information.",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Change in beneficial ownership due to management decisions – hiring a new CEO, creation of a new senior officer position, someone with the right to inherit ownership interest obtains direct control of this interest, etc.",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Changes in beneficial owner's name or identifying document number – moving to a new state and getting a new state-issued ID would require an update to address, ID number, and image of the ID.",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Changes in your reporting company's information – modifications in the reporting company's details, such as its business address or adding a new DBA (doing business as) name.",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "\nIf you have any questions about this BOI Report please reach out to us.\n",
    },
  ],
  SelfBill: [
    {
      attributes: {
        color: "#1b6f91",
        bold: true,
      },
      insert: "{Date}",
    },
    {
      insert:
        "\n\nGreetings,\n\nWe have completed {Company}'s BOI Report using Secure Compliance. Now it's your turn!\nHere are your next steps for {Company}'s BOI compliance:\n\nReview your Report",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert: "Sign below to Authorize this filing",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert: "Report any Changes of Information to us immediately",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "\nFrom here, we will be managing {Company}'s compliance. This means that when certain information changes about your company or your beneficial owners, you will need to inform us immediately, as we only have 30 days to report the change to FinCEN. Here's the most common triggers that result in needing to file an updated report:\nChange in the residential address of a beneficial owner.",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Death of a beneficial owner - an updated report will need to be filed that excludes this late owner's information.",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Change in beneficial ownership due to management decisions - hiring a new CEO, creation of a new senior officer position, someone with the right to inherit ownership interest obtains direct control of this interest, ect.",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Changes in beneficial owners's name or identifying document number - moving to a new state and getting a new state-issued ID would require an update to address, ID number, and image of the ID.",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Changes in your reporting company's information - modifications in the reporting company's details, such as its business address or adding a new DBA (doing business as) name.",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "\nIf you have any questions on what changes will result in the need for an updated BOI Report, please reach out to us.\n",
    },
  ],
  Direct: [
    {
      attributes: {
        color: "#1b6f91",
      },
      insert: "{Date}",
    },
    {
      insert:
        "\n\nGreetings,\n\n{Company}'s BOI Report is nearly done, let’s finish this up! Here are your next steps for {Company}'s BOI compliance:\n\nReview your Report",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert: "Sign and Authorize this Filing",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Watch your Email for FinCEN’s Acceptance or Rejection of your Filing",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Report any Changes of Information in your Dashboard and Resubmit",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "\nSince you will be managing {Company}'s compliance, this means that when certain information changes about your company or your beneficial owners, you will need to file an updated report with FinCEN ",
    },
    {
      attributes: {
        bold: true,
      },
      insert: "within 30 days",
    },
    {
      insert:
        " of when the change occurred. But don't worry, we'll help you through it! Here's the most common triggers that result in needing to file an updated report:\nChange in the residential address of a beneficial owner",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Death of a beneficial owner – an updated report will need to be filed that excludes this late owner's information.",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Change in beneficial ownership due to management decisions – hiring a new CEO, creation of a new senior officer position, someone with the right to inherit ownership interest obtains direct control of this interest, etc.",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Changes in beneficial owner's name or identifying document number – moving to a new state and getting a new state-issued ID would require an update to address, ID number, and image of the ID.",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "Changes in your reporting company's information – modifications in the reporting company's details, such as its business address or adding a new DBA (doing business as) name.",
    },
    {
      attributes: {
        list: "bullet",
      },
      insert: "\n",
    },
    {
      insert:
        "\nPlease review {Company}’s BOI Report and authorize the filing. If you have any questions please reach out to us.\n",
    },
  ],
};
export const coverLetterHeader = `
  <div style="margin-bottom: 20px; padding: 0px 12px;">{header}</div>
`;
export const logoAndAddress = `
<div style="display:flex;flex-direction:row;justify-content: space-between;">
  <div>{orgLogo}</div>
  <div>{orgAddress}</div>
</div>
`;
export const coverLetterFooter = `
<span style="padding: 0px 12px;display: flex;flex-direction: column; justify-content:space-between; height: 100% !important;">
  <div>
    <p>
      <em>
        I certify that I am authorized to file this BOIR on behalf of {Company}.
        I further certify, on behalf of {Company}, that the information contained in this BOIR is true, correct, and complete.
      </em>
    </p>
    <br />
    <div style="display:flex;flex-direction:row">
      <p style="color: #8C8C8C;border-top: 1px solid black;width: 145px;">Signature</p>
      <p style="color: #8C8C8C;border-top: 1px solid black;margin-left: 20px;width: 145px;">Date</p>
    </div>
  </div>
  {logoAndAddress}
</span>
`;

export const getLogo = (
  coverLetterData: ICoverLetterData,
  logoType: "file" | "default" | "org" = "default"
) => {
  let logo = "";
  if (logoType === "org" && coverLetterData.orgLogo) {
    logo = coverLetterData.orgLogo;
  } else if (logoType === "file") {
    logo = "/assets/images/SecureFILE_logo2.svg";
  } else {
    logo = "/assets/images/secure-compliance-words.svg";
  }
  return `<img style="max-width: 150px;max-height: 100px;" src="${logo}" alt="" />`;
};
export const getLogoAndAddressHtml = (
  coverLetterData: ICoverLetterData,
  logoType: "file" | "default" | "org" = "default"
) => {
  const logo = getLogo(coverLetterData, logoType);
  const address = `
    <strong>Secure Compliance, LLC</strong>
    <p style="margin:0px;color: #8C8C8C;">18 East Main Street, Suite 224 A</p>
    <p style="margin:0px;color: #8C8C8C;">Rapid City, SD 57701</p>
    <div style="color: #8C8C8C;display: flex; flex-direction: row;">
    <a style="margin-right: 5px" href="mailto:info@securecompliance.us">info@securecompliance.us</a>
    /
    <a style="margin-left: 5px" href="tel:605-646-3531">605-646-3531</a>
    </div>
  `;
  return logoAndAddress
    .replaceAll("{orgLogo}", logo)
    .replaceAll("{orgAddress}", address);
};
