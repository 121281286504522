import { DeltaOperation } from "quill";
import {
  IGroupToBinMap,
  IPublicOwnerMap,
  ISelfBillBulkSubscription,
  Permission,
} from ".";

export interface IOrganization {
  name: string;
  orgPhone?: string | null;
  orgEmail?: string | null;
  groups?: IGroupMap; // sub-collection
  entities?: IGroupToBinMap; // sub-collection
  publicOwners?: IPublicOwnerMap; // sub-collection
  roles?: { [roleId: string]: IRole }; // sub-collection
  logoUrl?: string;
  stripeCustomerId?: string | null;
  selfBilled?: boolean | null;
  enterpriseFeaturesEnabled?: boolean | null;
  customEmailContent?: ICustomEmailContent | null;
  bulkPricingCheckoutType?: ISelfBillBulkSubscription | null;
  disableAutoCollect?: boolean | null;
}

export interface ICustomEmailContent {
  customOwnerCollectionEmailContent: string;
  customCoverLetterContent: ICustomCoverLetterContent;
}
export interface ICustomCoverLetterContent {
  Handoff: DeltaOperation[];
  SelfBill: DeltaOperation[];
  Direct?: DeltaOperation[];
}

export interface IGroupMap {
  [groupId: string]: IGroup;
}

export interface IGroup {
  entities: IPublicEntity[];
  userIds: string[];
  name: string;
  default: boolean;
  active: boolean;
}

/**
 * @description The public entity is visible to all users in the organization.
 */
export interface IPublicEntity {
  id: string;
  name: string;
}

export interface IRole {
  name: string;
  permissions: Permission[];
}
