import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(value: Array<string> | null): Array<string>
  transform<TObject extends object, TKey extends keyof TObject>(value: Array<TObject> | null, key: TKey): Array<TObject>
  transform<TObject extends object, TKey extends keyof TObject>(value: Array<string | TObject> | null, key?: TKey): Array<string | TObject> | null {
    if (value === null) {
      return value;
    }

    if (key === undefined) {
      return (value as Array<string>).sort((a, b) =>
        a.localeCompare(b)
      );
    }

    return (value as Array<TObject>).sort((aObj, bObj) => {
      const aSortOption = aObj[key];
      const bSortOption = bObj[key];

      if (typeof aSortOption === 'string' && typeof bSortOption === 'string') {
        return aSortOption.localeCompare(bSortOption);
      }

      return 0;
    })
  }
}
