import { IGroupMap, Permission, Role } from ".";

export type UserType = "secure-pro" | "secure-file";

export interface IQuickStartStep {
  finished: boolean;
  position: number;
}

export interface IUserQuickStartState {
  [key: string]: IQuickStartStep;
  multiFactor: {
    finished: boolean;
    position: number;
  };
  users: {
    finished: boolean;
    position: number;
  };
  groups: {
    finished: boolean;
    position: number;
  };
  entities: {
    finished: boolean;
    position: number;
  };
}

export interface IUser {
  name: string;
  consentFirstName: string | null;
  consentLastName: string | null;
  email: string;
  orgId: string | null;
  skipTwoFactor: boolean;
  permissions: Permission[];
  roles: Role[]; // List of references to the roles in the user's org
  userType: UserType | null;
  stripeCustomerId: string | null;
  quickStartState: IUserQuickStartState | null;
  proAssociation: "referral" | "handoff" | null;
}

export interface IUserState {
  id: string;
  name: string;
  consentFirstName: string | null;
  consentLastName: string | null;
  email: string;
  skipTwoFactor: boolean;
  groups: IGroupMap;
  permissions: Permission[];
  role: Role;
  quickStartState: IUserQuickStartState | null;
}

export type SignUpProvider = "email" | "google" | "microsoft";
