import { Injectable, Renderer2, RendererFactory2, inject } from "@angular/core";
import { Functions } from "@angular/fire/functions";
import { Store } from "@ngxs/store";
import { httpsCallable } from "firebase/functions";
import html2pdf from "node_modules/html2pdf.js/src/index.js";
import Quill, { DeltaStatic } from "quill";
import {
  CoverLetterType,
  ICoverLetterData,
  ICustomCoverLetterContent,
  IEntity,
} from "src/app/core/interfaces";
import {
  coverLetterDefaults,
  coverLetterFooter,
  coverLetterHeader,
  getLogo,
  getLogoAndAddressHtml,
} from "src/app/modules/secure-pro/settings/components/billing/cover-letter-defaults";
import { SecureProStateModel } from "src/app/state/secure-pro-state/secure-pro-model.interface";
import { UserStateModel } from "src/app/state/user-state/user-model.interface";
import { ICreateReportRequest, ICreateReportResponse } from "../interfaces";

export interface IHTML2PDFOptions {
  margin?: number;
  filename?: string;
  image?: { type: string; quality: number };
  pagebreak?: { mode: "css" | "legacy" };
  html2canvas?: {
    scale: number;
    useCORS: boolean;
  };
  enableLinks?: boolean;
  jsPDF?: { unit: string; format: string; orientation: string };
}

@Injectable({
  providedIn: "root",
})
export class PdfService {
  private functions: Functions = inject(Functions);
  private store: Store = inject(Store);
  private rendererFactory = inject(RendererFactory2);
  private renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);

  private userName = this.store.selectSnapshot(
    (state) => (state.user as UserStateModel).name
  );

  private orgLogo = this.store.selectSnapshot(
    (state) => (state.securePro as SecureProStateModel).org.logoUrl
  );

  private org = this.store.selectSnapshot(
    (state) => (state.securePro as SecureProStateModel).org
  );

  private getOptions(filename: string): IHTML2PDFOptions {
    return {
      margin: 0,
      filename,
      image: { type: "jpeg", quality: 0.3 },
      html2canvas: { scale: 6, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
  }

  generate(elementId = "print-out", filename = "secure-compliance-document") {
    filename += ` - ${new Date().toLocaleDateString("en-US")}.pdf`;
    const element = document.getElementById(elementId);
    html2pdf(element, this.getOptions(filename));
  }

  async generateCoverLetterAndReport(
    coverLetterType: CoverLetterType,
    entity: IEntity,
    elementId = "print-out"
  ) {
    await this.generateCoverLetter(coverLetterType, entity, elementId);

    let htmlContent =
      "<div style='page-break-after: always; margin-bottom: 20px;'></div>";

    htmlContent += await this.generateReport(entity, elementId, true);
    const element = document.getElementById(elementId);

    if (element) {
      element.insertAdjacentHTML("beforeend", htmlContent);
    }
  }

  async generateCoverLetter(
    coverLetterType: CoverLetterType,
    entity: IEntity | undefined,
    elementId = "print-out",
    customContentOverride: ICustomCoverLetterContent | null = null
  ) {
    const coverLetterData = this.coverLetterData(entity);
    let content = coverLetterData.customCoverLetterContent?.[coverLetterType];
    if (!content) {
      content = coverLetterDefaults[coverLetterType];
    }
    if (customContentOverride && customContentOverride[coverLetterType]) {
      content = customContentOverride[coverLetterType];
    }
    let stringContent = JSON.stringify(content)
      .replaceAll("{Company}", coverLetterData.companyName)
      .replaceAll("{Signer}", coverLetterData.userName)
      .replaceAll("{Date}", coverLetterData.currentDate);
    if (this.org?.name) {
      stringContent = stringContent.replaceAll("{Pro Org Name}", this.org.name);
    }
    content = JSON.parse(stringContent);
    const el = document.getElementById(elementId);
    if (!el) throw new Error("Element not found");
    el.style.setProperty("border", "none");
    this.renderer.addClass(el, "print-out");
    const quill = new Quill(el, {
      readOnly: true,
      modules: {
        toolbar: null,
      },
      theme: "snow",
    });
    quill.setContents({ ops: content } as DeltaStatic);

    let footer = coverLetterFooter.replaceAll(
      "{Company}",
      coverLetterData.companyName
    );
    // Don't add the logo and address to self bill
    if (coverLetterType === "SelfBill") {
      footer = footer.replaceAll("{logoAndAddress}", "");
    } else {
      footer = footer.replaceAll(
        "{logoAndAddress}",
        getLogoAndAddressHtml(coverLetterData)
      );
    }
    el.insertAdjacentHTML("beforeend", footer);
    let header = coverLetterHeader;
    if (coverLetterType === "Direct") {
      header = header.replaceAll(
        "{header}",
        getLogoAndAddressHtml(coverLetterData, "file")
      );
    } else {
      header = header.replaceAll("{header}", getLogo(coverLetterData, "org"));
    }
    el.insertAdjacentHTML("afterbegin", header);
  }

  async generateReport(
    entity: IEntity | undefined,
    elementId = "print-out",
    returnHtml = false
  ) {
    const generateReportCallable = httpsCallable<
      ICreateReportRequest,
      ICreateReportResponse
    >(this.functions, "createHtmlReport");

    const sendReportResponse = await generateReportCallable({
      entityData: entity,
      currentDate: this.currentDate,
    });

    if (returnHtml) return sendReportResponse.data.htmlTemplate;

    const element = document.getElementById(elementId);

    if (element) {
      element.innerHTML = sendReportResponse.data.htmlTemplate;
    }

    return null;
  }

  private get currentDate() {
    const date = new Date();
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  private coverLetterData(entity: IEntity | undefined): ICoverLetterData {
    return {
      companyName: entity?.legalName || "",
      orgLogo: this.orgLogo,
      userName: this.userName,
      currentDate: this.currentDate,
      customCoverLetterContent:
        this.org?.customEmailContent?.customCoverLetterContent,
    };
  }
}
