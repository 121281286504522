export interface IAppEnv {
  firebase: IFirebaseEnv;
  stripeKey: string;
  envType: EnvType;
}

/** Convenience wrapper to contain easy access callback to env questions */
export interface ISmartAppEnv extends IAppEnv {
  isProd: () => boolean;
  isStaging: () => boolean;
  isStagingPreview: () => boolean;
  isDev: () => boolean;
  isLocal: () => boolean;
}

export type EnvType =
  | "production"
  | "staging"
  | "stagingPreview"
  | "development"
  | "local";

export interface IFirebaseEnv {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export interface IScreenResolution {
  mobile: { minWidth: number; maxWidth: number };
  tablet: { minWidth: number; maxWidth: number };
  desktop: { minWidth: number; maxWidth: number };
}

export type ScreenSize = "mobile" | "tablet" | "desktop";
