<h2 mat-dialog-title>Filing Status</h2>
<mat-dialog-content>
  <p>
    Status: <b>{{ data.status.submissionStatus }}</b>
  </p>
  <div
    *ngIf="
      data.status?.errors &&
      data.status.submissionStatus !== 'submission_accepted'
    "
  >
    <p>Errors:</p>
    <ul>
      <li *ngFor="let error of data.status?.errors">
        <p>
          <b>{{ error.ErrorCode }} </b>- {{ error.ErrorText }}
        </p>
      </li>
    </ul>
  </div>
  <div
    *ngIf="
      data.status?.validationErrors &&
      data.status.submissionStatus !== 'submission_accepted'
    "
  >
    <p>Validation Errors:</p>
    <ul>
      <li *ngFor="let error of data.status?.validationErrors">
        <p>
          {{ error.errorMessage || error }}
        </p>
      </li>
    </ul>
  </div>
  <div *ngIf="data.status?.submissionStatus === 'submission_accepted'">
    <p>BOIRID: {{ data.status.BOIRID }}</p>
    <p>FincenId: {{ data.status.fincenID }}</p>
  </div>
</mat-dialog-content>
